import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import { Container } from "../components/Container";

const Contact = () => (
  <Layout heroSection={false} contactFooter={false}>
    <SEO title="Thank you" />
    <Container>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          textAlign: "center",
          padding: "20px 0",
          height: 400,
        }}
      >
        <h1>Thank you! I'll try to get back to you shortly</h1>
      </div>
    </Container>
  </Layout>
);

export default Contact;
